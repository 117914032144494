import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/kernel-v2/kernel-v2/src/modules/layouts/default_layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Flash = makeShortcode("Flash");
const Card = makeShortcode("Card");
const StatusBanner = makeShortcode("StatusBanner");
const InfoBlock = makeShortcode("InfoBlock");
const Box = makeShortcode("Box");
const Aligner = makeShortcode("Aligner");
const Button = makeShortcode("Button");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "️-confianza",
      "style": {
        "position": "relative"
      }
    }}>{`✍️ Confianza`}<a parentName="h1" {...{
        "href": "#%EF%B8%8F-confianza",
        "aria-label": "️ confianza permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h1>
    <p>{`¿Qué es la confianza? ¿En quién confías y por qué? ¿Un mundo sin confianza sería más rico o más pobre? `}</p>
    <hr></hr>
    <h2 {...{
      "id": "mentirosos-y-tramposos",
      "style": {
        "position": "relative"
      }
    }}>{`Mentirosos y tramposos`}<a parentName="h2" {...{
        "href": "#mentirosos-y-tramposos",
        "aria-label": "mentirosos y tramposos permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h2>
    <p>{`Bitcoin empezó con la máxima `}<strong parentName="p">{`"No confíes, verifica"`}</strong>{`. `}</p>
    <p>{`Es decir, la confianza solo puede comenzar donde termina la verificación. La confianza solo es
`}<em parentName="p">{`significativa`}</em>{` una vez que hemos entendido completamente cómo la gente puede mentir. `}</p>
    <p>{`Debido a que podemos codificar las formas en que es posible hacer trampa, también podemos
escribir reglas de software ejecutables, con resultados deterministas, que prevengan las
trampas en los protocolos que usamos para definir y transferir valor. `}</p>
    <p>{`Esto demuestra cómo usar los `}<a parentName="p" {...{
        "href": "/learn/module-0/play-of-pattern"
      }}>{`opuestos complementarios`}</a>{` como un modo de pensamiento
para identificar patrones significativos. Podemos construir protocolos con sólidas garantías de
confianza definiendo claramente `}<strong parentName="p">{`y codificando`}</strong>{` lo que significa mentir. Para entender
la confianza, tenemos que conocer los detalles de todos los posibles engaños.`}</p>
    <p>{`Estas `}<a parentName="p" {...{
        "href": "https://github.com/jimmysong/programmingbitcoin/blob/master/ch07.asciidoc"
      }}>{`reglas claramente definidas y codificadas`}</a>{` `}<strong parentName="p">{`no`}</strong>{` significan que ya no necesitamos confiar en absoluto. Esto significa que `}</p>
    <blockquote>
      <p parentName="blockquote">{`💡 hay un `}<em parentName="p">{`cambio implícito`}</em>{`, de confiar en aquellos que poseen los medios a través de los
cuales  transferimos valor, a aquellos con quienes realmente estamos realizando transacciones.`}</p>
    </blockquote>
    <p>{`Tomémonos un momento para reflexionar sobre esta afirmación.`}</p>
    <Flash mdxType="Flash">
      <Card mdxType="Card">
        <p>{`P: ¿Cómo podemos construir protocolos con garantías sólidas de confianza?`}</p>
        <p>{`R: Al definir claramente y codificar lo que significa mentir.`}</p>
      </Card>
    </Flash>
    <h2 {...{
      "id": "vires-in-numeris",
      "style": {
        "position": "relative"
      }
    }}>{`Vires in numeris`}<a parentName="h2" {...{
        "href": "#vires-in-numeris",
        "aria-label": "vires in numeris permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h2>
    <p>{`Lo anterior es otra máxima primitiva de Bitcoin que significa "Fuerza en números". `}</p>
    <p>{`Se remonta a Pitágoras, para quien "Todas las cosas son número". El cambio implícito hacia
confiar en aquellos con los que realmente estamos realizando transacciones se consigue
debido a un cambio fundamental en el lenguaje por el cual se define el valor. En lugar de
un decreto reglamentario, impuesto por prosa legal, y tribunales humanos respaldados por la
amenaza de violencia; Bitcoin permite que una red de peers cree las condiciones requeridas
para una moneda funcional solo a través de las matemáticas, aplicadas por el cálculo determinista.`}</p>
    <p>{`El dicho tiene ese `}<em parentName="p">{`doble sentido`}</em>{` que implica tanto que el uso de números y el consenso
matemático nos da la resistencia para establecer lo que Andreas Antonopoulos llamará más tarde
'hechos incuestionables', al mismo tiempo que apunta a lo que da poder y sentido a cualquier
narrativa: la comunidad que lo cree. Cuanto más sucintamente podamos expresar las verdades
compartidas, más fácil será verificar (y por lo tanto confiar) en los sistemas que usamos. Esto implica que`}</p>
    <blockquote>
      <p parentName="blockquote">{`💡 La confianza tiene algo que ver con la verdad. `}</p>
    </blockquote>
    <p>{`La verdad verificable públicamente significa que más humanos pueden llegar a un consenso
sobre el estado básico de sus realidades compartidas y realizar transacciones de formas
cada vez más valiosas basadas en nuevos tipos de confianza.`}</p>
    <p>{`Es un `}<strong parentName="p">{`ciclo profundo de retroalimentación`}</strong>{`: usar un lenguaje universal (las matemáticas) para
definir de manera sucinta lo que significa hacer trampa, imponer sanciones de manera
determinista y ejecutar en función de sus definiciones (código), lo que permite que más
humanos experimenten con la confianza interpersonal de formas anteriormente inimaginables.`}</p>
    <p>{`Para soñar ideas importantes debes pensar como un idealista; para construir sistemas que
estén a la altura de esos sueños, debes `}<a parentName="p" {...{
        "href": "https://theconvivialsociety.substack.com/p/the-questions-concerning-technology"
      }}>{`pensar como un adversario`}</a>{`.`}</p>
    <Flash mdxType="Flash">
      <Card mdxType="Card">
        <p>{`P: ¿Cómo podemos hacer que sea más fácil verificar y confiar en los sistemas que usamos?`}</p>
        <p>{`R: Expresando sucintamente las verdades compartidas.`}</p>
      </Card>
    </Flash>
    <h2 {...{
      "id": "acces-ibilidad",
      "style": {
        "position": "relative"
      }
    }}>{`Acces-ibilidad`}<a parentName="h2" {...{
        "href": "#acces-ibilidad",
        "aria-label": "acces ibilidad permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h2>
    <p>{`Por último, no nos interesa la confianza basada en la fe ciega. Estamos interesados ​​en
la verific`}<strong parentName="p">{`abilidad`}</strong>{` determinista entre peers. El código fuente de Bitcoin puede ser auditado
por cualquier persona, en cualquier parte del mundo, por lo que no solo nos interesa la
capacidad, sino también el `}<strong parentName="p">{`acceso`}</strong>{`. La confianza, en un sentido práctico del día a día,
tiene mucho que ver con la transparencia y la educación. `}</p>
    <p>{`Esto requiere un cierto tipo de innovación arquitectónica en la estructura misma del dinero:
no más clientes y servidores; todos somos peers. Lee el siguiente material
seleccionado, `}<a parentName="p" {...{
        "href": "/learn/module-0/money-language"
      }}>{`El dinero habla`}</a>{`, para tener una idea de lo que es una arquitectura de dinero
peer-to-peer y lo que el dinero `}<em parentName="p">{`como protocolo`}</em>{` podría llegar a significar en nuestro tiempo.
Como Nicky Case destaca a continuación, todo se reduce a esta idea fundamental de la teoría del juego: `}</p>
    <StatusBanner warning sticky mdxType="StatusBanner">
      <p><strong parentName="p">{`Somos`}</strong>{` el entorno de los de demás.`}</p>
    </StatusBanner>
    <hr></hr>
    <h3 {...{
      "id": "más-allá-en-la-madriguera-del-conejo",
      "style": {
        "position": "relative"
      }
    }}>{`Más allá en la madriguera del conejo`}<a parentName="h3" {...{
        "href": "#m%C3%A1s-all%C3%A1-en-la-madriguera-del-conejo",
        "aria-label": "más allá en la madriguera del conejo permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h3>
    <p>{`Por supuesto, todo esto plantea la pregunta: "¿Qué es la verdad?" Tengamos en cuenta que
arriba estamos interesados ​​en `}<em parentName="p">{`la verdad compartida`}</em>{`, es decir, el consenso. La Verdad
Absoluta está más allá del alcance de este curso, aunque puedes usar el mismo framework
para pensar al respecto. Uno no se acerca a un absoluto tratando de definirlo, sino
teniendo claro `}<strong parentName="p">{`lo que no es`}</strong>{`. Si eres lo suficientemente honesto y claro, entonces
lo que quede después de toda tu `}<a parentName="p" {...{
        "href": "https://openjournals.library.sydney.edu.au/index.php/SSR/article/viewFile/202/181"
      }}>{`negación`}</a>{` tiene que ser necesariamente la verdad.`}</p>
    <h2 {...{
      "id": "otras-referencias",
      "style": {
        "position": "relative"
      }
    }}>{`Otras referencias`}<a parentName="h2" {...{
        "href": "#otras-referencias",
        "aria-label": "otras referencias permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h2>
    <InfoBlock mdxType="InfoBlock">
      <Box mdxType="Box">
        <p><strong parentName="p">{`Confianza`}</strong></p>
        <p><img parentName="p" {...{
            "src": "/images/mod0-img/trust.jpg",
            "alt": "Trust"
          }}></img></p>
        <p>{`Este es un juego web interactivo realmente maravilloso
diseñado para enseñarte todo lo que necesitas saber
sobre la teoría de juegos. ¡Disfrútalo!`}</p>
        <Aligner center mdxType="Aligner">
          <Button to="https://maeriens.github.io/trust/" mdxType="Button">
    Jugar al juego
          </Button>
        </Aligner>
      </Box>
    </InfoBlock>
    <hr></hr>
    <p><em parentName="p">{`Nuestro objetivo es que Kernel sea una entorno de alta confianza, detacado por la generosidad comunitaria entre compañer@s talentosos y de ideas afines. Algunos de los aprendizajes clave del juego definen cómo pensamos sobre el programa, así como el espacio Web 3 en general.`}</em></p>
    <p><img parentName="p" {...{
        "src": "/images/mod0-img/trust1.png",
        "alt": "Trust 1"
      }}></img></p>
    <p><img parentName="p" {...{
        "src": "/images/mod0-img/trust2.png",
        "alt": "Trust 2"
      }}></img></p>
    <p><img parentName="p" {...{
        "src": "/images/mod0-img/trust3.png",
        "alt": "Trust 3"
      }}></img></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      